<template>
  <div>
    <KTCodePreview v-bind:title="'Doanh thu theo sản phẩm'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-md-5">
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="ml-1">
          <div class="col-md-12">
            <div class="row">
              <b-input
                size="sm"
                placeholder="Nhập tên, mã, mã vạch sản phẩm"
                v-model="searchProduct"
                class="col-md-3 mb-4"
              ></b-input>
              <div class="col-md-4">
                <vue-autosuggest
                  class="col-md-10"
                  v-model="selectedCateName"
                  :suggestions="filteredCateOptions"
                  @selected="onSelectedCate"
                  :limit="200"
                  @input="onInputCateChange"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Chọn danh mục',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.suggestionName }}
                  </div></vue-autosuggest
                >
              </div>
              <b-col md="2" class="pl-0">
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.start"
                  v-model="dpForm.startDate"
                ></date-picker>
              </b-col>
              <b-col md="2">
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.end"
                  v-model="dpForm.endDate"
                ></date-picker>
              </b-col>
            </div>
          </div>
        </b-row>
        <b-row class="ml-1">
          <div class="col-md-12">
            <div class="row mb-4">
              <div class="col-md-3 pl-0">
                <div class="row">
                  <label class="col-md-4 d-flex align-items-center"
                    >Thể loại:</label
                  >
                  <b-form-select
                    size="sm"
                    v-model="selectedProductType"
                    :options="listProductType"
                    value-field="id"
                    text-field="name"
                    class="col-md-8"
                  ></b-form-select>
                </div>
              </div>
              <div class="col-md-4">
                <vue-autosuggest
                  class="col-md-10"
                  v-model="selectedCate2Name"
                  :suggestions="filteredCate2Options"
                  @selected="onSelectedCate2"
                  :limit="200"
                  @input="onInputCate2Change"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Chọn danh mục nội bộ',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.suggestionName }}
                  </div></vue-autosuggest
                >
              </div>
              <b-col md="2" class="pl-0">
                <div>
                  <!-- <multiselect
                    v-model="value"
                    :options="options"
                    :multiple="true"
                    track-by="code"
                    :close-on-select="false"
                    @select="onSelect($event)"
                    @remove="onRemove($event)"
                    :show-labels="false"
                    :showNoResults="true"
                    :showPointer="false"
                    :custom-label="customLabel"
                    placeholder="Chọn cửa hàng"
                  >
                    <template slot="tag">{{ '' }}</template>
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ value.length }} cửa hàng đã chọn</span
                      ></template
                    >
                    <span
                      class="checkbox-label"
                      slot="option"
                      slot-scope="scope"
                      @click.self="select(scope.option)"
                    >
                      {{ scope.option.name }}
                      <input
                        class="test"
                        type="checkbox"
                        v-model="scope.option.checked"
                        @focus.prevent
                      />
                    </span>
                    <span
                      class="checkbox-label"
                      style="font-size: 12px"
                      slot="noResult"
                    >
                      Không có kết quả
                    </span>
                  </multiselect> -->
                  <BDropdownCustom
                    v-model="value"
                    :options="options"
                    :placeholder="'cửa hàng'"
                    :searchable="true"
                    :matchKey="['name']"
                    :limit="5"
                  />
                  <!-- </div> -->
                </div>
              </b-col>
              <b-col md="3">
                <vue-autosuggest
                  class="col-md-9"
                  v-model="searchEmployee"
                  :suggestions="filteredOptionsEmployee"
                  @selected="onSelectedEmployee"
                  :limit="10"
                  @input="onInputChangeEmployee"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Nhân viên bán hàng',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.fullName }}
                  </div></vue-autosuggest
                >
              </b-col>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="md=col-6">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter()"
              class="col-md-8 mb-4 ml-4"
              >Lọc</b-button
            >
          </div>
        </b-row>
        <b-row>
          <b-col>
            <p class="text-center text-title">Tổng bán</p>
            <b-row>
              <p class="col-md-4 text-lable-total">Số lượng:</p>
              <p class="col-md-8">{{ formatMoney(totalSale) }}</p>
            </b-row>
            <b-row>
              <p class="col-md-4 text-lable-total">Số tiền:</p>
              <p class="col-md-8">{{ formatMoney(totalSellingAmount) }}</p>
            </b-row>
          </b-col>
          <b-col>
            <p class="text-center text-title">DT bán sau CK</p>
            <b-row>
              <p class="col-md-4 text-lable-total">Giá trị:</p>
              <p class="col-md-8">
                {{ formatMoney(totalRevenueAfterDiscount) }}
              </p>
            </b-row>
          </b-col>
          <b-col>
            <p class="text-center text-title">Tổng trả</p>
            <b-row>
              <p class="col-md-4 text-lable-total">Số lượng:</p>
              <p class="col-md-8">{{ formatMoney(totalReturn) }}</p>
            </b-row>
            <b-row>
              <p class="col-md-4 text-lable-total">Số tiền:</p>
              <p class="col-md-8">{{ formatMoney(totalReturnAmount) }}</p>
            </b-row>
          </b-col>
          <b-col>
            <p class="text-center text-title">Tổng trả sau CK</p>
            <b-row>
              <p class="col-md-4 text-lable-total">Giá trị:</p>
              <p class="col-md-8">
                {{ formatMoney(totalRefundAfterDiscount) }}
              </p>
            </b-row>
          </b-col>
          <b-col>
            <p class="text-center text-title">Tổng doanh thu</p>
            <b-row>
              <p class="col-md-4 text-lable-total">Giá trị:</p>
              <p class="col-md-8">{{ formatMoney(totalRevenue) }}</p>
            </b-row>
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="listItem"
            :fields="fields"
            bordered
            hover
            :busy="onLoading"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="8"></vcl-table>
            </template>
            <template v-slot:cell(productName)="row">
              <span
                v-if="row.item.barCode"
                v-text="row.item.barCode"
                style="white-space: normal; font-size: 12px; font-weight: 500"
              ></span>
              <p
                v-if="row.item.productCode"
                style="font-size: 12px; font-weight: 500; margin-bottom: 0"
              >
                ( {{ row.item.productCode }} )
              </p>
              <span
                v-text="row.item.productName"
                style="white-space: normal"
              ></span>
            </template>
            <template v-slot:cell(sellingPrice)="row">
              <div class="text-right">
                <span
                  v-text="formatMoney(row.item.sellingPrice)"
                  class="text-right"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalSale)="row">
              <div class="text-right">
                <span
                  v-text="formatMoney(row.item.totalSale)"
                  class="text-right"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalSaleAmount)="row">
              <div class="text-right">
                <span
                  v-text="formatMoney(row.item.totalSaleAmount)"
                  class="text-right"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalRefund)="row">
              <div class="text-right">
                <span
                  v-text="formatMoney(row.item.totalRefund)"
                  class="text-right text-danger"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalRefundAmount)="row">
              <div class="text-right">
                <span
                  v-text="formatMoney(row.item.totalRefundAmount)"
                  class="text-right text-danger"
                ></span>
              </div>
            </template>
            <template v-slot:cell(discountAmount)="row">
              <div class="text-right">
                <span
                  v-text="formatMoney(row.item.discountAmount)"
                  class="text-right"
                ></span>
              </div>
            </template>
            <template v-slot:cell(totalRevenue)="row">
              <div class="text-right">
                <span
                  v-text="formatMoney(row.item.totalRevenue)"
                  class="text-right"
                ></span>
              </div>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ formatMoney(totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scope>
.d-flex {
  display: -webkit-box !important;
}
.text-title {
  font-weight: 600;
  font-size: 16px;
}
.text-lable-total {
  font-weight: 600;
}
</style>
<style>
.labelInput {
  font-weight: 600;
}
.productCode:hover {
  text-decoration: underline;
}

.inputText {
  width: 22%;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link {
  font-weight: 500;
  font-size: 1rem;
}

.customPagination /deep/ .page-item .page-link:hover i {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.selectedProClass {
  width: 4%;
  text-align: center;
}
.productNameClass {
  width: 20%;
}
.productNameNotPriceClass {
  width: 20%;
}
.providerClass {
  width: 12%;
}
.storeClass {
  width: 12%;
  align-items: center;
}
.importPriceProClass {
  width: 10%;
}
.salePriceProClass {
  width: 10%;
}
.statusProClass {
  width: 15%;
}
.dropdown-header {
  color: #3699ff !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
/* a {
  width: 100%;
} */
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

#autosuggest {
  padding-left: 0px;
  padding-right: 25px;
}
.table-wrapper {
  overflow-x: scroll;
  overflow-y: visible;
}
.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}
.multiselect {
  min-height: initial;
}
.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 12px;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}
.table-wrap {
  width: 100%;
  overflow-x: auto;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { currencyMask, removeAccents } from './../../../utils/common';
// import axios from 'axios';
import decounce from 'debounce';
import axios from 'axios';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { vi } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import { BASE_URL, TIME_TRIGGER } from './../../../utils/constants';
import fileDownload from '../../../utils/file-download';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      fields: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '20%',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
        },
        {
          key: 'totalSale',
          label: 'Tổng SL bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
        },
        {
          key: 'totalSaleAmount',
          label: 'Tổng tiền bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
        },
        {
          key: 'totalRefund',
          label: 'Tổng SL trả',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
        },
        {
          key: 'totalRefundAmount',
          label: 'Tổng tiền trả',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
        },
        {
          key: 'totalRevenue',
          label: 'Doanh thu',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      onLoading: false,
      numberOfPage: 0,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      mask: currencyMask,
      listProductType: [
        { id: null, name: 'Tất cả' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
        { id: 3, name: 'Sản phẩm combo' },
        { id: 4, name: 'Sản phẩm dịch vụ' },
      ],
      selectedProductType: null,
      value: [],
      options: [],
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      cate2Options: [
        {
          data: [],
        },
      ],
      filteredCate2Options: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      dpForm: {
        startDate: moment().format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      format: 'dd-MM-yyyy',
      language: vi,
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      selectedEmployee: null,
      searchEmployee: '',
      totalSale: 0,
      totalReturn: 0,
      totalSellingAmount: 0,
      totalReturnAmount: 0,
      totalRevenue: 0,
      listItem: [],
      totalRevenueAfterDiscount: 0,
      totalRefundAfterDiscount: 0,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    BDropdownCustom,
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Doanh thu theo sản phẩm', route: '/revenue-report/by-product' },
      { title: 'Doanh thu theo sản phẩm' },
    ]);
    this.fetchStoreForOption();
    this.fetchStoreByUser();
    this.checkViewOriginalPrice();
  },
  created() {
    let searchProduct = this.$route.query.searchProduct;
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    this.fetchCategory();
    this.fetchInternalCategory();
    this.checkViewOriginalPrice();
  },
  methods: {
    formatMoney: function (number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchData: function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!searchFromDay) {
        alert('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!searchToDay) {
        alert('Bạn chưa chọn ngày kết thúc!');
        return;
      }
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      };
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      if (this.searchEmployee.trim() === '') {
        this.selectedEmployee = null;
      }

      const param = {
        page: this.page,
        limit: 10,
        searchProduct: this.searchProduct,
        storeIds: listId,
        productType: this.selectedProductType,
        cateId: this.selectedCateId,
        internalCateId: this.selectedCate2Id,
        fromDate: searchFromDay,
        toDate: searchToDay,
        saleId: this.selectedEmployee,
      };

      const paramQuery = {
        params: param,
      };

      this.onLoading = true;
      this.totalRevenue = 0;
      this.totalSale = 0;
      this.totalSellingAmount = 0;
      this.totalReturn = 0;
      this.totalReturnAmount = 0;
      this.listItem = [];

      ApiService.query('revenueReport/', paramQuery).then(({ data }) => {
        this.listItem = data.data.data;
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;
        this.totalRevenue = data.data.totalRevenue;
        this.totalSale = data.data.totalSale;
        this.totalSellingAmount = data.data.totalSaleAmount;
        this.totalReturn = data.data.totalRefund;
        this.totalReturnAmount = data.data.totalRefundAmount;
        this.totalRevenueAfterDiscount = data.data.totalRevenueAfterDiscount;
        this.totalRefundAfterDiscount = data.data.totalRefundAfterDiscount;
        this.onLoading = false;
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    reportClick: async function () {
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      }; 
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      if (this.searchEmployee.trim() === '') {
        this.selectedEmployee = null;
      }
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!searchFromDay) {
        alert('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!searchToDay) {
        alert('Bạn chưa chọn ngày kết thúc!');
        return;
      }
      if (listId.length === 0) {
        this.options.forEach((element) => {
          listId.push(element.code);
        });
      }

      const params = {
        searchProduct: this.searchProduct,
        storeIds: listId,
        productType: this.selectedProductType,
        cateId: this.selectedCateId,
        internalCateId: this.selectedCate2Id,
        fromDate: searchFromDay,
        toDate: searchToDay,
        saleId: this.selectedEmployee,
      };

      const url = `${BASE_URL}revenueReport/export-excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    debounceInput: decounce(function () {
      this.fetchData();
    }, TIME_TRIGGER),
    fetchStoreForOption: async function () {
      ApiService.setHeader();
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
          this.options.push(option);
        });
      });
    },
    onSelect(option) {
      const index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = true;
    },
    onRemove(option) {
      const index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'by-product',
      });
      this.fetchData();
    },
    fetchCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [{ data: this.cateOptions[0].data }];
      });
    },
    fetchInternalCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.cate2Options[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.cate2Options[0].data.push(item);
          });
          this.filteredCate2Options = [{ data: this.cate2Options[0].data }];
        }
      );
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedCate2(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputCate2Change(text) {
      this.selectedCate2Name = text;

      const filteredData = this.cate2Options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCate2Options = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedEmployee(option) {
      this.selectedEmployee = option.item.id;
      this.searchEmployee = option.item.fullName;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      this.debounceInputEmployee();
    },
    debounceInputEmployee: decounce(function () {
      this.getListEmployee();
    }, TIME_TRIGGER),
    getListEmployee() {
      this.optionsEmployee[0].data = [];
      this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      ApiService.setHeader();
      ApiService.get(
        `employees/get-by-store?employeeName=${this.searchEmployee}&storeId=${listStoreId}`
      ).then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
        this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.options = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };

            this.options.push(option);
            if (!data.data.viewAll) {
              let index = this.options.findIndex(
                (item) => item.code == element.id
              );

              if (index !== -1) {
                this.options[index].checked = true;
                this.value.push(this.options[index]);
              }
            }
          });
        }
        this.fetchData();
        this.getListEmployee();
      });
    },
    checkViewOriginalPrice() {
      if (this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        this.fields.push({
          key: 'totalGrossProfit',
          label: 'Lợi nhuận',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        });
      }
    },
  },
};
</script>
